import React, { FC } from 'react';
import QrReader from 'react-qr-reader';
import {
  Route, Switch,
  BrowserRouter as Router,
} from 'react-router-dom';

import { Home } from './home/Home';
import { Labels } from './labels/Labels';
import { LoginPage } from './login/LoginPage';
import { Scanner } from './scanner/Scanner';
import { PrintPopulation } from './population/PrintPopulation';
import { DriveByList } from './labels/DriveByList';

export const Routes: FC = () => (
  <Router>
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/labels" exact={false}>
        <Labels />
      </Route>
      <Route path="/login" exact>
        <LoginPage />
      </Route>
      <Route path="/scan" exact>
        <Scanner />
      </Route>
      <Route path="/print-population" exact>
        <PrintPopulation />
      </Route>
      <Route path="/drive-by" exact={false}>
        <DriveByList />
      </Route>
    </Switch>
  </Router>
);
