import React, { FC, useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { IconButton, Popover, Typography } from '@material-ui/core';
import MultiSelect from 'react-multi-select-component';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { addTest, retrieveSurveyListings } from '../../clients/client';

interface Props {
  onAddTest: (testId: string) => void;
}

export const ScannerAddNewTestButton: FC<Props> = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipAnchor, setTooltipAnchor] = useState<HTMLSpanElement>();
  const [options, setOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [testName, setTestName] = useState<string>('');

  const handleAddTest = () => {
    if (selected.length > 0 && testName.length > 0) {
      setIsLoading(true);
      addTest(selected[0].value, testName).then((response) => {
        response.json().then((addTestResult) => {
          props.onAddTest(addTestResult.testId);
          setIsLoading(false);
          handleClose();
        });
      });
    }
  };

  const handleSelectSurvey = (newSelected: Option[]) => {
    setSelected(newSelected.filter((option) => !selected.includes(option)));
  };

  const handleClose = () => setIsDialogOpen(false);

  useEffect(() => {
    retrieveSurveyListings().then((surveyListings) => {
      setOptions(surveyListings.map((surveyListing) => ({
        label: `${moment(surveyListing.startDate).format('MM/DD/YY')} - ${surveyListing.name}`,
        value: surveyListing.id,
      })));
    });
  }, []);

  return (
    <div className="scanner__add-new-test-button">
      <Button
        variant="outlined"
        onClick={() => setIsDialogOpen(true)}
        onMouseEnter={(event) => {
          setIsTooltipOpen(true);
          setTooltipAnchor(event.currentTarget);
        }}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        Add new test
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="generate-labels-dialog"
      >
        <form>
          <DialogTitle id="form-dialog-title" className="batch-record-title">
            Scan samples into a new test
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="generate-labels-dialog__content">
            <DialogContentText>
              <div>Add a test name, and select which survey should hold the test</div>
            </DialogContentText>
            <TextField
              className="add-new-test__name"
              value={testName}
              label="Test name"
              onChange={(e) => {
                setTestName(e.target.value);
              }}
            />
            <MultiSelect
              options={options}
              hasSelectAll={false}
              value={selected}
              labelledBy="Select"
              onChange={handleSelectSurvey}
              isOpen
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleAddTest} disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Add test'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Popover
        className="tooltip"
        open={isTooltipOpen}
        anchorEl={tooltipAnchor}
        style={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Typography className="tooltip__text">Scan samples into a new test</Typography>
      </Popover>
    </div>
  );
};
