import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import { Survey } from '../../models/Survey';

interface Props {
    survey?: Survey;
}

export const GenerateLabelsButton: FC<Props> = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<Option[]>([]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = () => {
    const url = `/labels?survey=${props.survey?.id}${selected.map((testOption) => `&test=${testOption.value}`).join('')}`;
    window.open(url, '_blank');
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (props.survey) {
      const testOptions = props.survey.tests.map((test) => ({ label: test.name, value: test.id }));
      setOptions(testOptions);
      setSelected(testOptions);
    }
  }, []);

  return (
    <>
      <Button
        className="survey-modal__labels-button"
        variant="contained"
        color="primary"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        Generate Labels
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="generate-labels-dialog"
      >
        <form>
          <DialogTitle id="form-dialog-title">
            Generate Labels
          </DialogTitle>
          <DialogContent className="generate-labels-dialog__content">
            <DialogContentText>
              <div>Select the tests to be included in the printed labels.</div>
              <div>Each test and sample will be assigned a unique barcode.</div>
            </DialogContentText>
            <MultiSelect
              options={options}
              value={selected}
              labelledBy="Select"
              onChange={setSelected}
              isOpen
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" onClick={handleSubmit}>
              Continue
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
