import { Sample } from '../models/Sample';

export const sortSamplesByLastName = (samples: Sample[]) => samples.sort((a: Sample, b: Sample) => {
  const componentsA = a.person.name.split(' ');
  const componentsB = b.person.name.split(' ');

  const lastNameA = componentsA[componentsA.length - 1].toLowerCase();
  const lastNameB = componentsB[componentsB.length - 1].toLowerCase();

  if (lastNameA < lastNameB) return -1;
  return 1;
});
