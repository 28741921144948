import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CircularProgress, Popover, Typography } from '@material-ui/core';
import { retrieveAllSurveys, retrieveSurvey } from '../../clients/client';
import { Survey } from '../../models/Survey';
import './Labels.scss';
import { Label } from './Label';
import { generateEan8 } from '../../helpers/barcodes';
import { sortSamplesByLastName } from '../../helpers/samples';

type LabelEntry = { content?: string; id?: string };

export const Labels: FC = () => {
  const maxLabelsPerPage = 80;
  const [labels, setLabels] = useState<LabelEntry[][]>();
  const query = new URLSearchParams(useLocation().search);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipAnchor, setTooltipAnchor] = useState<HTMLDivElement>();
  const [isLoading, setIsLoading] = useState(true);

  const handleFinishedLabel = () => {
    if (isLoading) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const surveyId = query.get('survey');
    if (surveyId) {
      retrieveSurvey(surveyId).then((survey: Survey) => {
        const labelContent: LabelEntry[][] = [[]];
        if (survey) {
          const selectedTestIds = query.getAll('test');
          const tests = survey.tests.filter((test) => selectedTestIds.includes(test.id));
          tests.forEach((test) => {
            if (labelContent[labelContent.length - 1].length === maxLabelsPerPage) {
              labelContent.push([]);
            } else if (labelContent[0].length) {
              const remainder = labelContent[labelContent.length - 1].length % 4;
              const remainingLabelsOnLine = remainder ? 4 - remainder : remainder;
              for (let i = 0; i < remainingLabelsOnLine + 4; i++) {
                labelContent[labelContent.length - 1].push({});
                if (labelContent[labelContent.length - 1].length === maxLabelsPerPage) {
                  labelContent.push([]);
                  break;
                }
              }
            }
            const id = generateEan8(test.id, 'test');
            labelContent[labelContent.length - 1].push({
              content: `${test.name || `Test #${test.id}`}\n${survey.startDate}`,
              id,
            });
            sortSamplesByLastName(test.samples).forEach((sample) => {
              if (!sample.deleted) {
                if (labelContent[labelContent.length - 1].length === maxLabelsPerPage) {
                  labelContent.push([]);
                }
                labelContent[labelContent.length - 1].push({
                  content: `${sample.person.name}\nID: ${sample.person.apid}\n${survey.startDate}`,
                  id: generateEan8(sample.id, 'sample'),
                });
              }
            });
          });
          setLabels(labelContent);
        }
      });
    }
  }, []);

  return (
    <div>
      {isLoading && (
      <div className="loading-bar">
        <Typography variant="h4">Generating labels...</Typography>
      </div>
      )}
      {labels?.map((page) => (
        <div
          className={`labels__container ${isLoading ? ' labels__container--loading' : ''}`}
          onClick={() => {
            setIsTooltipOpen(false);
            setTimeout(() => {
              window.print();
            }, 200);
          }}
          key={page[0].id}
          onMouseEnter={(event) => {
            setIsTooltipOpen(true);
            setTooltipAnchor(event.currentTarget);
          }}
          onMouseLeave={() => setIsTooltipOpen(false)}
        >
          {page.map((label, index) => (
            <Label index={index} text={label.content} barcode={label.id} onDone={handleFinishedLabel} />
          ))}
        </div>
      ))}
      <Popover
        className="tooltip"
        open={isTooltipOpen}
        anchorEl={tooltipAnchor}
        style={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        container={tooltipAnchor?.parentElement}
        disableRestoreFocus
      >
        <Typography className="tooltip__text">Click Anywhere to Print</Typography>
      </Popover>
    </div>
  );
};
