import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { matchSorter } from 'match-sorter';
import { Person } from '../../models/Person';
import { addSample } from '../../clients/client';

const filter = createFilterOptions<OptionType>();

interface Props {
  subjects: Person[];
  onUpdateTest: () => Promise<Boolean>;
  testId: string;
}

interface OptionType {
  name?: string;
  apid?: string;
  title: string;
  id?: string;
}

export default function AddSampleRow(props: Props) {
  const [value, setValue] = useState<OptionType | null>(null);
  const [open, toggleOpen] = useState(false);
  const [subjects, setSubjects] = useState<OptionType[]>([]);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    setSubjects(props.subjects.map((person) => ({
      title: `${person.name} (${person.apid})`,
      name: person.name,
      apid: person.apid,
      id: person.id,
    })));
  }, [props.subjects]);

  const handleClose = () => {
    setDialogValue({
      title: '',
      apid: '',
      id: '',
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    apid: '',
    id: '',
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      id: dialogValue.id,
    });
    setIsAdding(true);
    addSample(props.testId, dialogValue.title, dialogValue.id).then(() => {
      props.onUpdateTest().then(() => {
        setValue(null);
        setIsAdding(false);
        handleClose();
      });
    });
  };

  return (
    <>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                title: newValue,
                apid: '',
                id: '',
              });
            });
          } else if (newValue && newValue.name) {
            toggleOpen(true);
            setDialogValue({
              title: newValue.name,
              apid: newValue.apid || '',
              id: newValue.id!,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          // const filtered = filter(options, params) as OptionType[];
          const filtered = matchSorter(options, params.inputValue, { keys: ['title'] });

          if (params.inputValue !== '') {
            filtered.push({
              name: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={subjects}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.name) {
            return option.name;
          }
          return option.title;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.title}
        style={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Add a sample" />
        )}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add a sample</DialogTitle>
          <DialogContent>
            <DialogContentText>
              If the subject does not have a student / faculty ID, please leave the ID field blank.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.title}
              onChange={(event) => setDialogValue({ ...dialogValue, title: event.target.value })}
              label="Name"
              type="text"
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.apid}
              onChange={(event) => setDialogValue({ ...dialogValue, apid: event.target.value })}
              label="ID"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" disabled={isAdding}>
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={isAdding}>
              {isAdding ? 'Adding...' : 'Add'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
