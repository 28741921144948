import React, { FC, useEffect, useState } from 'react';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddSampleRow from '../survey/AddSampleRow';
import { Sample } from '../../models/Sample';
import { retrieveTest, toggleTestResult } from '../../clients/client';
import { TestTitle } from '../survey/TestTitle';
import { Person } from '../../models/Person';
import { Test } from '../../models/Test';
import { SubjectNode } from '../../models/SubjectNode';
import './TestCard.scss';
import { SampleRow } from './SampleRow';
import { sortSamplesByLastName } from '../../helpers/samples';

interface Props {
    test: Test;
    reload: () => void;
    population?: SubjectNode[];
    openTests: string[];
    setOpenTests: (updated: string[]) => void;
    subjects: Person[];
}

export const TestCard: FC<Props> = (props) => {
  const [isSettingPositive, setIsSettingPositive] = useState(false);
  const [isSettingNegative, setIsSettingNegative] = useState(false);
  const [isSettingInconclusive, setIsSettingInconclusive] = useState(false);
  const [disabled, setDisabled] = useState(props.test.status === 'CREATED' || props.test.samples.filter((s) => s.status === 'PROCESSED').length === 0);
  const [test, setTest] = useState<Test>(props.test);

  const handleUpdateSample = (sample: Sample) => {
    const newTest = { ...test, samples: test.samples.filter((s) => s.id !== sample.id).concat([sample]) };
    setTest(newTest);
    getTestProcessedStatus(newTest);
  };

  const handleUpdateTest = (): Promise<Boolean> => retrieveTest(test.id).then((newTest) => {
    if (newTest) {
      setTest(newTest);
      getTestProcessedStatus(newTest);
    }
    return true;
  });

  const getTestProcessedStatus = (newTest: Test) => {
    if (test.status !== 'POSITIVE' && test.status !== 'NEGATIVE') {
      const nonDeletedSamples = newTest.samples.filter((s) => !s.deleted);
      if (nonDeletedSamples.length && nonDeletedSamples.every((s) => s.status !== 'CREATED')) {
        if (test.status !== 'PENDING_RESULTS') {
          setTest({ ...newTest, status: 'PENDING_RESULTS' });
          setDisabled(false);
        }
      } else if (test.status !== 'CREATED') {
        setTest({ ...newTest, status: 'CREATED' });
        setDisabled(true);
      }
    }
  };

  useEffect(() => {
    getTestProcessedStatus(props.test);
    setTest(props.test);
  }, [props.test]);

  return (
    <div className="card" key={test.id}>
      <Typography
        gutterBottom
        variant="h5"
        component="h2"
        className={`card__title${
          test.status === 'POSITIVE' ? ' card__title--positive'
            : test.status === 'NEGATIVE' ? ' card__title--negative'
              : test.status === 'INCONCLUSIVE' ? ' card__title--inconclusive'
                : test.status === 'PENDING_RESULTS' ? ' card__title--processed' : ''
        }`}
      >
        <TestTitle test={test} handleSave={props.reload} population={props.population} />
        <div className="title__buttons">
          <IconButton
            className="title__button"
            onClick={() => {
              setIsSettingPositive(true);
              toggleTestResult(test.id, 'POSITIVE').then(() => {
                if (test.status === 'POSITIVE') {
                  test.status = 'PENDING_RESULTS';
                } else {
                  test.status = 'POSITIVE';
                }
                setIsSettingPositive(false);
              });
            }}
          >
            {isSettingPositive
              ? (
                <CircularProgress
                  className="sample-row__loading-spinner"
                />
              ) : test.status === 'POSITIVE'
                ? <AddCircleOutlinedIcon className="plus" />
                : <AddCircleOutlineOutlinedIcon className="plus" />}
          </IconButton>
          <IconButton
            className="title__button"
            onClick={() => {
              setIsSettingNegative(true);
              toggleTestResult(test.id, 'NEGATIVE').then(() => {
                if (test.status === 'NEGATIVE') {
                  test.status = 'PENDING_RESULTS';
                } else {
                  test.status = 'NEGATIVE';
                }
                setIsSettingNegative(false);
              });
            }}
          >
            {isSettingNegative
              ? (
                <CircularProgress
                  className="sample-row__loading-spinner"
                />
              ) : test.status === 'NEGATIVE'
                ? <RemoveCircleOutlinedIcon className="minus" />
                : <RemoveCircleOutlineOutlinedIcon className="minus" />}
          </IconButton>
          <IconButton
            className="title__button"
            onClick={() => {
              setIsSettingInconclusive(true);
              toggleTestResult(test.id, 'INCONCLUSIVE').then(() => {
                if (test.status === 'INCONCLUSIVE') {
                  test.status = 'PENDING_RESULTS';
                } else {
                  test.status = 'INCONCLUSIVE';
                }
                setIsSettingInconclusive(false);
              });
            }}
          >
            {isSettingInconclusive
              ? (
                <CircularProgress
                  className="sample-row__loading-spinner"
                />
              ) : test.status === 'INCONCLUSIVE'
                ? <HelpIcon className="inconclusive" />
                : <HelpOutlineOutlinedIcon className="inconclusive" />}
          </IconButton>
        </div>
      </Typography>
      <Typography
        gutterBottom
        variant="body2"
        component="div"
        className={`card__title--subtitle card__title${
          test.status === 'POSITIVE' ? ' card__title--positive'
            : test.status === 'NEGATIVE' ? ' card__title--negative'
              : test.status === 'INCONCLUSIVE' ? ' card__title--inconclusive'
                : test.status === 'PENDING_RESULTS' ? ' card__title--processed' : ''
        }`}
      >
        {`Processed ${test.samples.filter((s) => s.status === 'PROCESSED' && !s.deleted).length} / ${test.samples.filter((s) => !s.deleted).length}`}
      </Typography>
      <Divider className="card__divider" />
      <div className="card__samples">
        {props.openTests.includes(test.id) && sortSamplesByLastName(test.samples).map((sample: Sample) => (
          <SampleRow
            sample={sample}
            onUpdateSample={handleUpdateSample}
          />
        ))}
        {props.openTests.includes(test.id) && (
        <Typography
          variant="body2"
          component="div"
          className="sample-row"
        >
          <AddSampleRow
            subjects={props.subjects}
            onUpdateTest={handleUpdateTest}
            testId={test.id}
          />
        </Typography>
        )}
      </div>
      <div className="card__expand-button">
        {props.openTests.includes(test.id) ? (
          <IconButton onClick={() => {
            props.setOpenTests(props.openTests.filter((id) => id !== test.id));
          }}
          >
            <ExpandLessIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => {
            props.setOpenTests(props.openTests.concat(test.id));
          }}
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
