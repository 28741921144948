import { Moment } from 'moment';
import { SubjectNode } from '../models/SubjectNode';
import { Survey } from '../models/Survey';
import { Person } from '../models/Person';
import { TestStatus } from '../models/Statuses';
import { Test } from '../models/Test';
import { Sample } from '../models/Sample';
import { SurveyListing } from '../models/SurveyListing';

interface RetrieveSubjectsResponse {
    nodes: SubjectNode[];
}

let backend = '/api';
if (process.env.REACT_APP_ENVIRONMENT === 'local') {
  backend = 'http://localhost:8080';
}
export const serverUrl = backend;

export const retrieveSubjectNodes = (): Promise<SubjectNode[]> => (
  fetch(`${serverUrl}/api/subject/nodes`, { credentials: 'include', headers: { Authorization: '' } })
    .then(async (response) => {
      const retrieveSubjectsResponse: RetrieveSubjectsResponse = await response.json();
      return retrieveSubjectsResponse.nodes;
    })
    .catch((response) => {
      window.location.pathname = '/login';
      return response;
    })
);

export const retrievePeople = (): Promise<Person[]> => (
  get('subject')
);

export const retrieveAllSurveys = (): Promise<Survey[]> => (
  get('survey/all')
);

export const retrieveSurvey = (surveyId: string): Promise<Survey> => (
  get(`survey?id=${surveyId}`)
);

export const retrieveSurveyListings = (): Promise<SurveyListing[]> => (
  get('survey/listing')
);

export const retrieveTest = (testId: string): Promise<Test | undefined> => (
  get(`survey/test?id=${testId}`)
);

export const retrieveSample = (sampleId: string): Promise<Sample> => (
  get(`survey/sample?id=${sampleId}`)
);

export const createSurvey = (name: string, selectedDate: Moment, checked: string[], driveBy: boolean, surveyId?: string): Promise<Response> => (
  post('survey', {
    name, selectedDate: selectedDate.format('yyyy-MM-DD'), checked, driveBy, surveyId,
  })
);

export const processSample = (sampleId: string): Promise<Response> => (
  post('survey/process-sample', { sampleId })
);

export const moveAndProcessSample = (sampleId: string, newTestId: string): Promise<Response> => (
  post('survey/move-and-process-sample', { sampleId, newTestId })
);

export const toggleBadSample = (sampleId: string): Promise<Response> => (
  post('survey/toggle-bad-sample', { sampleId })
);

export const toggleAbsentSample = (sampleId: string): Promise<Response> => (
  post('survey/toggle-absent-sample', { sampleId })
);

export const toggleMissingSample = (sampleId: string): Promise<Response> => (
  post('survey/toggle-missing-sample', { sampleId })
);

export const deleteSample = (sampleId: string): Promise<Response> => (
  post('survey/delete-sample', { sampleId })
);

export const addSample = (testId: string, personName: string, personId?: string): Promise<Response> => (
  post('survey/add-sample', { testId, personName, personId })
);

export const toggleTestResult = (testId: string, testStatus: TestStatus): Promise<Response> => (
  post('survey/toggle-test-result', { testId, testStatus })
);

export const recordTestResult = (testId: string, testStatus: TestStatus): Promise<Response> => (
  post('survey/record-test-result', { testId, testStatus })
);

export const recordTestResults = (testIds: string[], testStatus: TestStatus): Promise<Response> => (
  post('survey/record-test-results', { tests: testIds.map((testId) => ({ testId, testStatus })) })
);

export const recordNewTestResults = (testIds: string[], testStatus: TestStatus): Promise<Response> => (
  post('survey/record-new-test-results', { tests: testIds.map((testId) => ({ testId, testStatus })) })
);

export const copyTestsToNewSurvey = (testIds: string[], selectedDate: Moment): Promise<Response> => (
  post('survey/copy-tests-to-new-survey', { testIds, selectedDate: selectedDate.format('yyyy-MM-DD') })
);

export const addTest = (surveyId: string, testName?: string): Promise<Response> => (
  post('survey/add-test', { surveyId, testName })
);

export const processTest = (testId: string): Promise<Response> => (
  post('survey/process-test', { testId })
);

export const changeTestName = (testId: string, testName?: string): Promise<Response> => (
  post('survey/change-test-name', { testId, testName })
);

export const deleteTest = (testId: string): Promise<Response> => (
  post('survey/delete-test', { testId })
);

export const changeSurveyName = (surveyId: string, surveyName?: string): Promise<Response> => (
  post('survey/change-survey-name', { surveyId, surveyName })
);

export const deleteSurvey = (surveyId: string): Promise<Response> => (
  post('survey/delete-survey', { surveyId })
);

export const retrieveSurveyNote = (surveyId: string): Promise<{note: string}> => (
  get(`survey/${surveyId}/note`)
);

export const updateSurveyNote = (surveyId: string, updatedNote: string): Promise<Response> => (
  post(`survey/${surveyId}/note`, { updatedNote })
);

export const changePodName = (podId: string, newName: string): Promise<Response> => (
  post('subject/change-pod-name', { podId, newName })
);

export const deletePod = (podId: string): Promise<Response> => (
  post('subject/delete-pod', { podId })
);

export const changePersonApidAndName = (personId: string, newApid: string, newName: string): Promise<Response> => (
  post('subject/change-person-apid-and-name', { personId, newApid, newName })
);

export const switchPod = (personId: string, newPodId: string): Promise<Response> => (
  post('subject/switch-pod', { personId, newPodId })
);

export const addPod = (gradeId: string, newPodName: string): Promise<Response> => (
  post('subject/add-pod', { gradeId, newPodName })
);

export const addPerson = (podId: string, personName: string, personId?: string): Promise<Response> => (
  post('subject/add-person', { podId, personName, personId })
);

export const login = (user: string, pass: string): Promise<boolean> => {
  const myHeaders = new Headers();
  const token = btoa(`${user}:${pass}`);
  myHeaders.append('Authorization', `Basic ${token}`);
  myHeaders.append('Cache', 'no-cache');

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
  };

  return fetch(`${serverUrl}/login`, requestOptions)
    .then((response) => {
      if (response.status !== 200) return false;
      window.location.pathname = '/';
      return true;
    })
    .catch(() => false);
};

export const logout = () => {
  fetch(`${serverUrl}/logout`, { credentials: 'include' });
  window.location.pathname = '/';
};

const get = (endpoint: string): Promise<any> => (
  fetch(`${serverUrl}/api/${endpoint}`, { method: 'GET', credentials: 'include', headers: { Authorization: '' } })
    .then((response) => response.json())
    .catch((response) => {
      if (response.status === 400) {
        window.location.pathname = '/login';
      }
    })
);

const post = (endpoint: string, body: any): Promise<any> => fetch(`${serverUrl}/api/${endpoint}`,
  {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
    },
    credentials: 'include',
    body: JSON.stringify(body),
  })
  .then((response) => {
    if (response.status === 400) {
      window.location.pathname = '/login';
    }
    return response;
  });
