import React, { FC, useEffect, useState } from 'react';
import {
  Paper, Table, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { retrieveSurvey } from '../../clients/client';
import '../population/PopulationTable.scss';
import { Sample } from '../../models/Sample';
import { generateEan8 } from '../../helpers/barcodes';
import { sortSamplesByLastName } from '../../helpers/samples';

const Barcode = require('react-barcode');

export const DriveByList: FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const [samples, setSamples] = useState<Sample[]>([]);

  useEffect(() => {
    retrieveSurvey(query.get('survey')!).then((survey) => {
      const sortedSamples = sortSamplesByLastName(survey.tests.flatMap((test) => test.samples));
      setSamples(sortedSamples);
    });
  }, []);

  useEffect(() => {
    if (samples.length) {
      window.print();
    }
  }, [samples]);

  return (
    <TableContainer
      component={Paper}
      className="drive-by-list"
      onClick={() => {
        window.print();
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>School ID</TableCell>
            <TableCell>Done</TableCell>
            <TableCell align="right">Label</TableCell>
          </TableRow>
        </TableHead>
        {samples.map((sample, index) => (
          <TableRow className={`print-row ${index % 2 ? 'print-row--gray' : ''}`}>
            <TableCell>{sample.person.name}</TableCell>
            <TableCell>{sample.person.apid}</TableCell>
            <TableCell>☐</TableCell>
            <TableCell align="right">
              <Barcode
                className="label__barcode"
                value={generateEan8(sample.id, 'sample')}
                displayValue={false}
                margin={0}
              />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
};
