import React, { FC } from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import './SurveyTable.scss';
import { SurveyListing } from '../../models/SurveyListing';

interface Props {
  surveyListings?: SurveyListing[];
  handleOpenSurvey: (surveyId: string) => void;
}

export const SurveyTable: FC<Props> = (props) => {
  const tableHead = (
    <TableHead>
      <TableRow>
        <TableCell>Survey</TableCell>
        <TableCell align="right">Start Date</TableCell>
        <TableCell align="right">Samples Processed</TableCell>
        <TableCell align="right">Test Results Recorded</TableCell>
        <TableCell align="right">Negative Tests</TableCell>
        <TableCell align="right">Positive Tests</TableCell>
        <TableCell align="right">Inconcl. Tests</TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer component={Paper}>
      {props.surveyListings ? (
        <Table aria-label="simple table">
          {tableHead}
          <TableBody>
            {props.surveyListings?.map((surveyListing) => (
              <TableRow
                key={surveyListing.id}
                onClick={() => {
                  props.handleOpenSurvey(surveyListing.id);
                }}
                hover
              >
                <TableCell component="th" scope="row">
                  {surveyListing.name}
                </TableCell>
                <TableCell
                  align="right"
                >
                  {moment(surveyListing.startDate).format('MMM D, yyyy')}
                </TableCell>
                <TableCell align="right">
                  {surveyListing.samplesProcessed}
                </TableCell>
                <TableCell align="right">
                  {surveyListing.testResultsRecorded}
                </TableCell>
                <TableCell align="right" className="result-count result-count--negative">
                  {surveyListing.negativeTests}
                </TableCell>
                <TableCell align="right" className="result-count result-count--positive">
                  {surveyListing.positiveTests}
                </TableCell>
                <TableCell align="right" className="result-count result-count--inconclusive">
                  {surveyListing.inconclusiveTests}
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      ) : (
        <>
          <Table>{tableHead}</Table>
          <div className="progress-spinner"><CircularProgress /></div>
        </>
      )}
    </TableContainer>
  );
};
