import React, { FC } from 'react';
import useFitText from 'use-fit-text';

const Barcode = require('react-barcode');

interface Props {
    text?: string;
    barcode?: string;
    index: number;
    onDone: () => void;
}

export const Label: FC<Props> = (props) => {
  const { fontSize, ref } = useFitText({
    onFinish: () => {
      props.onDone();
    },
  });
  return (
    <div className="label" key={`label-${props.index}`}>
      {props.text && (
      <>
        <div
          className="label__content"
          ref={ref}
          style={{ fontSize, height: 35, width: 80 }}
        >
          {props.text.split('\n').map((line) => (
            <div>{line}</div>
          ))}
        </div>
        {props.barcode && (
        <Barcode className="label__barcode" value={props.barcode} displayValue={false} margin={0} />
        )}
      </>
      )}
    </div>
  );
};
