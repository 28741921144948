export type TestStatus =
    | 'CREATED'
    | 'PROCESSING'
    | 'PENDING_RESULTS'
    | 'INCONCLUSIVE'
    | 'POSITIVE'
    | 'NEGATIVE';

export const RECORDED_STATUSES = ['INCONCLUSIVE', 'POSITIVE', 'NEGATIVE'];

export type SampleStatus =
    | 'CREATED'
    | 'COLLECTED'
    | 'BAD_SAMPLE'
    | 'ABSENT'
    | 'MISSING'
    | 'PROCESSED';
