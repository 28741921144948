const cdigit = require('cdigit');

export const generateEan8 = (value: string, type: 'test' | 'sample') => {
  const numToPad = 6 - value.length;
  let padded = value;
  for (let i = 0; i < numToPad; i++) {
    padded = `0${padded}`;
  }
  const withTypeDigit = type === 'test' ? `0${padded}` : `1${padded}`;
  return cdigit.luhn.generate(withTypeDigit);
};
