import React, { FC, useEffect, useState } from 'react';
import { ButtonBase, Card, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Survey } from '../../models/Survey';
import { retrieveSurveyNote, updateSurveyNote } from '../../clients/client';

interface Props {
    survey?: Survey;
}

export const SurveyNotes: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [updatedNote, setUpdatedNote] = useState<string>('');

  useEffect(() => {
    if (props.survey) {
      retrieveSurveyNote(props.survey?.id).then((response) => {
        setNote(response.note);
        setUpdatedNote(response.note);
      });
    }
  }, [props.survey]);

  useEffect(() => {
    setUpdatedNote(note);
  }, [isOpen]);

  const saveNote = (event: React.MouseEvent) => {
    event.preventDefault();
    if (props.survey) {
      setIsSaving(true);
      updateSurveyNote(props.survey.id, updatedNote).then(() => {
        setNote(updatedNote);
        setIsSaving(false);
        handleClose();
      });
    }
  };

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <ButtonBase
        className="survey-notes__button"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Card className="survey-notes__card">
          <Typography
            className="survey-notes__title"
            gutterBottom
            variant="h5"
            component="h2"
          >
            Notes
          </Typography>
          <div className="survey-notes__content">
            {note.split('\n').map((line) => <div>{line}</div>)}
          </div>
        </Card>
      </ButtonBase>

      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form>
          <DialogTitle id="form-dialog-title">Edit survey notes</DialogTitle>
          <DialogContent>
            <TextField
              className="survey-notes__text-field"
              multiline
              variant="outlined"
              defaultValue={updatedNote}
              onChange={(e) => {
                setUpdatedNote(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" disabled={isSaving}>
              Cancel
            </Button>
            <Button color="primary" onClick={saveNote} disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
