import React, { FC, useEffect, useState } from 'react';
import {
  Paper, Table, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import { SubjectNode } from '../../models/SubjectNode';
import { retrieveSubjectNodes } from '../../clients/client';
import { Person } from '../../models/Person';
import './PopulationTable.scss';

export const PrintPopulation: FC = () => {
  const [people, setPeople] = useState<Person[]>([]);

  useEffect(() => {
    retrieveSubjectNodes().then((population: SubjectNode[]) => {
      const schools = population[0].children;
      const personNodes = schools?.flatMap((school) => school.children).flatMap((grade) => grade?.children).flatMap((pod) => pod?.children);
      const personArray: Person[] = [];
      personNodes?.forEach((node) => {
        if (node) {
          personArray.push({
            id: node.value.split('-')[1],
            name: node.label,
            apid: node.apid || '',
          });
        }
      });
      const sortedPeople = personArray.sort((a: Person, b: Person) => {
        const componentsA = a.name.split(' ');
        const componentsB = b.name.split(' ');

        const lastNameA = componentsA[componentsA.length - 1].toLowerCase();
        const lastNameB = componentsB[componentsB.length - 1].toLowerCase();

        if (lastNameA < lastNameB) return -1;
        return 1;
      });
      setPeople(sortedPeople);
    });
  }, []);

  useEffect(() => {
    if (people.length) {
      window.print();
    }
  }, [people]);

  return (
    <TableContainer
      component={Paper}
      className="print-population-table"
      onClick={() => {
        window.print();
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>School ID</TableCell>
            <TableCell>Done</TableCell>
          </TableRow>
        </TableHead>
        {people.map((person, index) => (
          <TableRow className={`print-row ${index % 2 ? 'print-row--gray' : ''}`}>
            <TableCell>{person.name}</TableCell>
            <TableCell>{person.apid}</TableCell>
            <TableCell>☐</TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
};
