import React from 'react';
import QrReader from 'react-qr-reader';
import './App.css';
import { Routes } from './components/Routes';

function App() {
  return (
    <div className="main-content">
      <Routes />
    </div>
  );
}

export default App;
