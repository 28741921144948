import React, { useEffect, useState } from 'react';

import {
  Button, FormControlLabel, Snackbar, Switch, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useSound from 'use-sound';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import {
  moveAndProcessSample,
  processSample, processTest, retrievePeople, retrieveSample, retrieveTest, toggleBadSample,
} from '../../clients/client';
import './Scanner.scss';
import { Test } from '../../models/Test';
import { TestCard } from '../test_card/TestCard';
import { Person } from '../../models/Person';
import successBeep from '../../assets/beep-07.mp3';
import errorBeep from '../../assets/error-beep.mp3';
import { Sample } from '../../models/Sample';
import { ScannerAddNewTestButton } from './ScannerAddNewTestButton';

export const Scanner = () => {
  const [scanResult, setScanResult] = useState<string | null>('');
  const [test, setTest] = useState<Test>();
  const [subjects, setSubjects] = useState<Person[]>([]);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState<'success' | 'info' | 'warning' | 'error'>();
  const [isProcessingSample, setIsProcessingSample] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [numSamplesRemaining, setNumSamplesRemaining] = useState(0);
  const [isProcessingTest, setIsProcessingTest] = useState(false);
  const [nextTest, setNextTest] = useState<Test>();
  const [currentId, setCurrentId] = useState<string>();
  const [shouldProcessSamplesOfAnyTest, setShouldProcessSamplesOfAnyTest] = useState<boolean>(true);
  const [shouldMoveToCurrentTest, setShouldMoveToCurrentTest] = useState<boolean>(false);
  const [playSuccessBeep] = useSound(successBeep);
  const [playErrorBeep] = useSound(errorBeep);
  let scanBuffer: string[] = [];

  const reload = () => {
    if (test) {
      retrieveTest(test.id).then((newTest) => {
        if (newTest !== test) {
          setTest(newTest);
        }
      });
    }
  };

  const handleToastClose = () => setIsToastOpen(false);

  const makeToast = (message: string, severity: 'success' | 'info' | 'warning' | 'error') => {
    handleToastClose();
    setToastMessage(message);
    setToastSeverity(severity);
    setIsToastOpen(true);
  };

  const handleBadSample = () => {
    setIsProcessingSample(false);
    if (currentId) {
      const sample = test?.samples.find((s) => s.id === currentId);
      if (sample?.status !== 'BAD_SAMPLE') {
        toggleBadSample(currentId).then(() => {
          makeToast('Marked as Bad Sample', 'warning');
          reload();
        });
      }
    }
  };

  const handleProcessTest = (newTest?: Test) => {
    if (test?.samples.some((s) => s.status === 'CREATED' && !s.deleted)) {
      setNumSamplesRemaining(test.samples.filter((s) => s.status === 'CREATED' && !s.deleted).length);
      setIsConfirmOpen(true);
      setNextTest(newTest);
    } else {
      handleContinueProcessTest(newTest);
    }
  };

  const handleContinueProcessTest = (newTest?: Test) => {
    const finishProcessing = () => {
      setIsProcessingTest(false);
      setIsConfirmOpen(false);
      setTest(newTest);
      setNextTest(undefined);
      if (newTest) makeToast(`Loaded test: ${newTest.name}`, 'success');
    };

    if (test?.samples.some((s) => s.status === 'CREATED' && !s.deleted)) {
      setIsProcessingTest(true);
      processTest(test?.id).then(() => { finishProcessing(); }).catch(() => { finishProcessing(); });
    } else {
      setTest(newTest);
      setNextTest(undefined);
      if (newTest) makeToast(`Loaded test: ${newTest.name}`, 'success');
    }
  };

  const scanTest = (testId: string, suppressSuccessBeep?: boolean): Promise<boolean> => (
    retrieveTest(testId).then((newTest) => {
      if (newTest?.id) {
        if (newTest !== test) {
          if (!suppressSuccessBeep) playSuccessBeep();
          handleProcessTest(newTest);
        }
      } else {
        playErrorBeep();
        makeToast('Test not found!', 'error');
      }
      return true;
    }));

  const scanSample = (sample: Sample): Promise<any> => {
    const { name } = sample.person;
    if (sample.status !== 'PROCESSED') {
      playSuccessBeep();
      makeToast(`${name}`, 'success');
      setIsProcessingSample(true);
      if (shouldMoveToCurrentTest && test) {
        return moveAndProcessSample(sample.id, test.id).then(() => {
          reload();
          return true;
        });
      }
      return processSample(sample.id).then(() => {
        reload();
        return true;
      });
    }
    playErrorBeep();
    makeToast(`Already processed sample: ${name}`, 'warning');
    return Promise.resolve();
  };

  const handleAddNewTest = (testId: string) => {
    scanTest(testId, true).then(() => {
      setShouldMoveToCurrentTest(true);
    });
  };

  useEffect(() => {
    window.addEventListener('keypress', (event) => {
      if (event.key === 'Enter' && scanBuffer.length) {
        setScanResult(scanBuffer.join(''));
        scanBuffer = [];
      } else if (scanBuffer.length || event.key === '1' || event.key === '0') {
        scanBuffer.push(event.key);
      }
    });
  }, []);

  useEffect(() => {
    setIsProcessingSample(false);
    const labelId = upcToId(scanResult);
    if (labelId) {
      setCurrentId(labelId.id);
      if (labelId.type === 'test') {
        scanTest(labelId.id);
      } else {
        const sample = test?.samples.find((s) => s.id === labelId.id);
        if (sample) {
          scanSample(sample);
        } else if (shouldMoveToCurrentTest) {
          retrieveSample(labelId.id).then((foundSample) => {
            scanSample(foundSample);
          });
        } else if (shouldProcessSamplesOfAnyTest) {
          retrieveSample(labelId.id).then((foundSample) => {
            scanTest(foundSample.testId, true).then(() => {
              scanSample(foundSample).then(() => {
                retrieveTest(foundSample.testId).then((newTest) => {
                  if (newTest !== test) {
                    setTest(newTest);
                  }
                });
              });
            });
          });
        } else {
          playErrorBeep();
          makeToast('Sample does not belong to this test', 'error');
        }
      }
    }
    setScanResult('');
  }, [scanResult]);

  useEffect(() => {
    retrievePeople().then((result) => {
      setSubjects(result);
    });
  }, [test]);

  return (
    <div className="scanner">
      <div className="scanner__content">
        <div className="scanner__controls">
          <FormControlLabel
            control={(
              <Switch
                checked={shouldProcessSamplesOfAnyTest}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setShouldProcessSamplesOfAnyTest(event.target.checked); }}
                name="checkedA"
                color="primary"
              />
              )}
            label="Process any sample"
          />
          <FormControlLabel
            control={(
              <Switch
                checked={shouldMoveToCurrentTest}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setShouldMoveToCurrentTest(event.target.checked); }}
                name="checkedA"
                color="primary"
              />
              )}
            label="Move to current test"
          />
          <ScannerAddNewTestButton onAddTest={handleAddNewTest} />
        </div>
        <div className={`test-container ${!test ? 'test-container--empty' : ''}`}>
          {test ? (
            <TestCard
              test={test}
              reload={reload}
              openTests={[test.id]}
              setOpenTests={() => {}}
              subjects={subjects}
            />
          )
            : (
              <Typography variant="h5">
                {shouldProcessSamplesOfAnyTest
                  ? 'Scan any label.' : 'Scan a test label to begin.'}
              </Typography>
            )}
          {test && (
          <Button
            className="next-test-button"
            variant="outlined"
            onClick={() => handleProcessTest()}
            disabled={isProcessingTest}
          >
            {isProcessingTest ? 'Processing...' : 'Done'}
          </Button>
          )}
        </div>
      </div>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={10000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert
          onClose={handleToastClose}
          severity={toastSeverity}
          action={isProcessingSample && (
            <Button color="inherit" size="small" variant="outlined" onClick={handleBadSample}>
              BAD SAMPLE
            </Button>
          )}
        >
          {isProcessingSample && <div>Processing Sample:</div>}
          {toastMessage}
        </Alert>
      </Snackbar>
      <Dialog open={isConfirmOpen}>
        <DialogTitle>Process Test</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {numSamplesRemaining}
            {' '}
            sample(s) still not marked.
          </DialogContentText>
          <DialogContentText>
            They will be marked
            {' '}
            <span className="special-text">
              <NotListedLocationIcon />
              {'  '}
              MISSING
            </span>
            {' '}
            and moved to the
            {' '}
            <span className="special-text">RESAMPLE</span>
            {' '}
            test.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsConfirmOpen(false);
              setScanResult('');
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleContinueProcessTest(nextTest)} disabled={isProcessingTest}>
            {isProcessingTest ? 'Processing...' : 'Continue'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

type LabelId = { type: 'test' | 'sample'; id: string };
const upcToId = (upc: string | null): LabelId | undefined => {
  if (upc) {
    const type = upc.startsWith('1') ? 'sample' : 'test';
    const id = parseInt(upc.substring(1, upc.length - 1), 10);
    return { type, id: `${id}` };
  }
  return undefined;
};
