import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import './PopulationTable.scss';
import '../survey/SurveyTable.scss';
import { useHistory } from 'react-router';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { SubjectNode } from '../../models/SubjectNode';
import {
  addPerson,
  addPod, changePersonApidAndName, changePodName, deletePod, switchPod,
} from '../../clients/client';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

interface Props {
    population?: SubjectNode[];
    reloadPopulation: () => void;
}

export const PopulationTable: FC<Props> = (props) => {
  const history = useHistory();
  const [openRows, setOpenRows] = useState<string[]>([]);

  const [pods, setPods] = useState<{id: string; label: string}[]>([]);
  const [newPods, setNewPods] = useState<{[gradeId: string]: string}>({});
  const [newPeopleNames, setNewPeopleNames] = useState<{[podId: string]: string}>({});
  const [newPeopleApids, setNewPeopleApids] = useState<{[podId: string]: string}>({});
  const [originalPodLabels, setOriginalPodLabels] = useState<{[id: string]: string}>({});
  const [podLabels, setPodLabels] = useState<{[id: string]: string}>({});
  const [editedPods, setEditedPods] = useState<{[id: string]: boolean}>({});

  const [originalPersonApids, setOriginalPersonApids] = useState<{[id: string]: string}>({});
  const [personApids, setPersonApids] = useState<{[id: string]: string}>({});
  const [originalPersonNames, setOriginalPersonNames] = useState<{[id: string]: string}>({});
  const [personNames, setPersonNames] = useState<{[id: string]: string}>({});
  const [editedPeople, setEditedPeople] = useState<{[id: string]: boolean}>({});

  const classes = useRowStyles();

  useEffect(() => {
    if (!props.population) return;
    const schools = props.population[0].children;
    const podNodes = schools!.flatMap((school) => school.children!.flatMap((grade) => grade.children!.flatMap((pod) => pod)));
    setPods(podNodes.map((node) => ({
      label: node.label,
      id: node.value.split('-')[1],
    })));
    podNodes.forEach((pod) => {
      podLabels[pod.value] = pod.label;
      originalPodLabels[pod.value] = pod.label;
      editedPods[pod.value] = false;
      pod.children?.forEach((person) => {
        originalPersonApids[person.value] = person.apid || '';
        personApids[person.value] = person.apid || '';

        originalPersonNames[person.value] = person.label || '';
        personNames[person.value] = person.label || '';

        editedPeople[person.value] = false;
      });
    });
    setPodLabels(podLabels);
    setEditedPods(editedPods);
  }, [props.population]);

  useEffect(() => {
    const newEditedPeople: {[id: string]: boolean} = {};
    Object.keys(personNames).forEach((personId) => {
      const nameChanged = personNames[personId] !== originalPersonNames[personId];
      const apidChanged = personApids[personId] !== originalPersonApids[personId];
      newEditedPeople[personId] = nameChanged || apidChanged;
    });
    setEditedPeople(newEditedPeople);
  }, [personNames, personApids]);

  const toggleRow = (rowValue: string) => {
    if (openRows.includes(rowValue)) {
      setOpenRows(openRows.filter((row) => row !== rowValue));
    } else {
      setOpenRows(openRows.concat(rowValue));
    }
  };

  const expandAll = () => {
    const newOpenRows: string[] = [];
    props.population![0].children?.forEach((school) => {
      newOpenRows.push(school.value);
      school.children?.forEach((grade) => {
        newOpenRows.push(grade.value);
        grade.children?.forEach((pod) => {
          newOpenRows.push(pod.value);
        });
      });
    });
    setOpenRows(newOpenRows);
  };

  const closeAll = () => {
    setOpenRows([]);
  };

  const printPopulation = () => {
    history.push('/print-population');
  };

  const gradeSorter = (gradeA: SubjectNode, gradeB: SubjectNode): number => {
    const gradeOrder = ['N3', 'PK', '0K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '15', '16'];
    return gradeOrder.indexOf(gradeA.label) - gradeOrder.indexOf(gradeB.label);
  };

  if (!props.population) return <div className="progress-spinner"><CircularProgress /></div>;
  const schools = props.population[0].children;

  return (
    <div>
      <div className="title">
        <Typography variant="h5">
          School Population
        </Typography>
        <div className="buttons">
          <Button onClick={expandAll} variant="outlined">Expand All</Button>
          <Button onClick={closeAll} variant="outlined">Close All</Button>
          <Button onClick={printPopulation} variant="outlined">Print</Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="expand-cell" />
              <TableCell>School</TableCell>
              <TableCell>Number of grades</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schools?.map((school) => (
              <>
                <TableRow className={`${classes.root}${openRows.includes(school.value) ? ' row--selected' : ''}`}>
                  <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => toggleRow(school.value)}>
                      {openRows.includes(school.value) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{school.label}</TableCell>
                  <TableCell>
                    {school.children?.length || 0}
                    {' '}
                    Grades
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openRows.includes(school.value)} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Table size="small" aria-label="simple table">
                          <TableHead className="table-head">
                            <TableRow>
                              <TableCell className="expand-cell" />
                              <TableCell>Grade</TableCell>
                              <TableCell>Number of pods</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {school.children?.sort(gradeSorter).map((grade) => (
                              <>
                                <TableRow className={`${classes.root}${openRows.includes(grade.value) ? ' row--selected' : ''}`}>
                                  <TableCell>
                                    <IconButton aria-label="expand row" size="small" onClick={() => toggleRow(grade.value)}>
                                      {openRows.includes(grade.value) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                  </TableCell>
                                  <TableCell>{grade.label}</TableCell>
                                  <TableCell>
                                    {!grade.children?.length ? '0 Pods' : grade.children.length === 1 ? '1 Pod' : `${grade.children.length} Pods`}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Collapse in={openRows.includes(grade.value)} timeout="auto" unmountOnExit>
                                      <Box margin={1}>
                                        <Table size="small" aria-label="simple table">
                                          <TableHead className="table-head">
                                            <TableRow>
                                              <TableCell className="expand-cell" />
                                              <TableCell>Pod</TableCell>
                                              <TableCell>Number of people</TableCell>
                                              <TableCell />
                                              <TableCell />
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {grade.children?.map((pod) => (
                                              <>
                                                <TableRow className={`${classes.root}${openRows.includes(pod.value) ? ' row--selected' : ''}${pod.value.split('-')[1] === '0' ? ' row--uncategorized' : ''}`}>
                                                  <TableCell>
                                                    <IconButton aria-label="expand row" size="small" onClick={() => toggleRow(pod.value)}>
                                                      {openRows.includes(pod.value) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                  </TableCell>
                                                  <TableCell>
                                                    <TextField
                                                      value={podLabels[pod.value]}
                                                      disabled={pod.value.split('-')[1] === '0'}
                                                      onChange={(e) => {
                                                        podLabels[pod.value] = e.target.value;
                                                        setPodLabels(podLabels);

                                                        const editedPodsCopy = Object.create(editedPods);
                                                        editedPodsCopy[pod.value] = e.target.value !== originalPodLabels[pod.value];
                                                        setEditedPods(editedPodsCopy);
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell>
                                                    {pod.children?.length || 0}
                                                    {' '}
                                                    People
                                                  </TableCell>
                                                  <TableCell>
                                                    <Button
                                                      variant="outlined"
                                                      disabled={!editedPods[pod.value]}
                                                      onClick={() => {
                                                        changePodName(pod.value.split('-')[1], podLabels[pod.value]).then(() => {
                                                          const originalPodLabelsCopy = Object.create(originalPodLabels);
                                                          originalPodLabelsCopy[pod.value] = podLabels[pod.value];
                                                          setOriginalPodLabels(originalPodLabelsCopy);
                                                          const editedPodsCopy = Object.create(editedPods);
                                                          editedPodsCopy[pod.value] = false;
                                                          setEditedPods(editedPodsCopy);
                                                          props.reloadPopulation();
                                                        });
                                                      }}
                                                    >
                                                      Save Changes
                                                    </Button>
                                                  </TableCell>
                                                  <TableCell>
                                                    <IconButton disabled={pod.value.split('-')[1] === '0'} onClick={() => deletePod(pod.value.split('-')[1]).then(() => props.reloadPopulation())}>
                                                      <DeleteOutlineIcon />
                                                    </IconButton>
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                    <Collapse in={openRows.includes(pod.value)} timeout="auto" unmountOnExit>
                                                      <Box margin={1}>
                                                        <Table size="small" aria-label="simple table">
                                                          <TableHead className="table-head">
                                                            <TableRow>
                                                              <TableCell className="expand-cell expand-cell--last" />
                                                              <TableCell>Name</TableCell>
                                                              <TableCell>School ID</TableCell>
                                                              <TableCell />
                                                              <TableCell />
                                                            </TableRow>
                                                          </TableHead>
                                                          <TableBody>
                                                            {pod.children?.map((person) => (
                                                              <TableRow>
                                                                <TableCell />
                                                                <TableCell>
                                                                  <TextField
                                                                    className="person-name-textfield"
                                                                    placeholder="Enter Full Name"
                                                                    value={personNames[person.value]}
                                                                    onChange={(e) => {
                                                                      const personNamesCopy = Object.create(personNames);
                                                                      personNamesCopy[person.value] = e.target.value;
                                                                      setPersonNames(personNamesCopy);
                                                                    }}
                                                                  />
                                                                </TableCell>
                                                                <TableCell>
                                                                  <TextField
                                                                    placeholder="Enter School ID"
                                                                    value={personApids[person.value]}
                                                                    onChange={(e) => {
                                                                      const personApidsCopy = Object.create(personApids);
                                                                      personApidsCopy[person.value] = e.target.value;
                                                                      setPersonApids(personApidsCopy);
                                                                    }}
                                                                  />
                                                                </TableCell>
                                                                <TableCell>
                                                                  <Autocomplete
                                                                    value={{ id: pod.value.split('-')[1], label: pod.label }}
                                                                    onChange={(event, newValue) => {
                                                                      if (typeof newValue !== 'string' && newValue?.id) {
                                                                        switchPod(person.value.split('-')[1], newValue.id).then(() => props.reloadPopulation());
                                                                      }
                                                                    }}
                                                                    id="free-solo-dialog-demo"
                                                                    options={pods}
                                                                    getOptionLabel={(option) => option.label}
                                                                    selectOnFocus
                                                                    clearOnBlur
                                                                    handleHomeEndKeys
                                                                    renderOption={(option) => option.label}
                                                                    style={{ width: 150 }}
                                                                    freeSolo
                                                                    renderInput={(params) => (
                                                                      <TextField {...params} label="Change Pod" />
                                                                    )}
                                                                  />
                                                                </TableCell>
                                                                <TableCell>
                                                                  <Button
                                                                    variant="outlined"
                                                                    disabled={!editedPeople[person.value]}
                                                                    onClick={() => {
                                                                      changePersonApidAndName(person.value.split('-')[1], personApids[person.value], personNames[person.value]).then(() => {
                                                                        const originalPersonApidsCopy = Object.create(originalPersonApids);
                                                                        originalPersonApidsCopy[person.value] = personApids[person.value];
                                                                        setOriginalPersonApids(originalPersonApidsCopy);

                                                                        const originalPersonNamesCopy = Object.create(originalPersonNames);
                                                                        originalPersonNamesCopy[person.value] = personNames[person.value];
                                                                        setOriginalPersonNames(originalPersonNamesCopy);

                                                                        const editedPeopleCopy = Object.create(editedPeople);
                                                                        editedPeopleCopy[person.value] = false;
                                                                        setEditedPeople(editedPeopleCopy);

                                                                        props.reloadPopulation();
                                                                      });
                                                                    }}
                                                                  >
                                                                    Save Changes
                                                                  </Button>
                                                                </TableCell>
                                                              </TableRow>
                                                            ))}
                                                            <TableRow>
                                                              <TableCell />
                                                              <TableCell>
                                                                <TextField
                                                                  placeholder="Enter Person Name"
                                                                  value={newPeopleNames[pod.value] || ''}
                                                                  onChange={(e) => {
                                                                    const newPeopleCopy = Object.create(newPeopleNames);
                                                                    newPeopleCopy[pod.value] = e.target.value;
                                                                    setNewPeopleNames(newPeopleCopy);
                                                                  }}
                                                                />
                                                              </TableCell>
                                                              <TableCell>
                                                                <TextField
                                                                  placeholder="Enter School ID"
                                                                  value={newPeopleApids[pod.value] || ''}
                                                                  onChange={(e) => {
                                                                    const newPeopleApidsCopy = Object.create(newPeopleApids);
                                                                    newPeopleApidsCopy[pod.value] = e.target.value;
                                                                    setNewPeopleApids(newPeopleApidsCopy);
                                                                  }}
                                                                />
                                                              </TableCell>
                                                              <TableCell />
                                                              <TableCell>
                                                                <Button
                                                                  variant="outlined"
                                                                  disabled={!(newPeopleNames[pod.value] && newPeopleNames[pod.value].length)}
                                                                  onClick={() => {
                                                                    addPerson(pod.value.split('-')[1], newPeopleNames[pod.value], newPeopleApids[pod.value]).then(() => {
                                                                      const newPeopleNamesCopy = Object.create(newPeopleNames);
                                                                      newPeopleNamesCopy[pod.value] = '';
                                                                      setNewPeopleNames(newPeopleNamesCopy);

                                                                      const newPeopleApidsCopy = Object.create(newPeopleApids);
                                                                      newPeopleApidsCopy[pod.value] = '';
                                                                      setNewPeopleApids(newPeopleApidsCopy);

                                                                      props.reloadPopulation();
                                                                    });
                                                                  }}
                                                                >
                                                                  Add New Person
                                                                </Button>
                                                              </TableCell>
                                                            </TableRow>
                                                          </TableBody>
                                                        </Table>
                                                      </Box>
                                                    </Collapse>
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            ))}
                                            <TableRow>
                                              <TableCell />
                                              <TableCell>
                                                <TextField
                                                  placeholder="Enter Pod Name"
                                                  value={newPods[grade.value] || ''}
                                                  onChange={(e) => {
                                                    const newPodsCopy = Object.create(newPods);
                                                    newPodsCopy[grade.value] = e.target.value;
                                                    setNewPods(newPodsCopy);
                                                  }}
                                                />
                                              </TableCell>
                                              <TableCell />
                                              <TableCell>
                                                <Button
                                                  variant="outlined"
                                                  disabled={!(newPods[grade.value] && newPods[grade.value].length)}
                                                  onClick={() => {
                                                    addPod(grade.value.split('-')[1], newPods[grade.value]).then(() => {
                                                      const newPodsCopy = Object.create(newPods);
                                                      newPodsCopy[grade.value] = '';
                                                      setNewPods(newPodsCopy);
                                                      props.reloadPopulation();
                                                    });
                                                  }}
                                                >
                                                  Add New Pod
                                                </Button>
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
