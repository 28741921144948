import React, { FC, useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { IconButton, Popover, Typography } from '@material-ui/core';
import RepeatIcon from '@material-ui/icons/Repeat';
import MultiSelect from 'react-multi-select-component';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { RECORDED_STATUSES } from '../../models/Statuses';
import { copyTestsToNewSurvey } from '../../clients/client';
import { Survey } from '../../models/Survey';

interface Props {
    survey: Survey;
    onSave: () => void;
    onOpen: () => void;
}

export const CopyToNewSurveyButton: FC<Props> = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipAnchor, setTooltipAnchor] = useState<HTMLSpanElement>();
  const [options, setOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(moment());

  const handleSelectPositive = () => {
    setSelected([...selected, ...options.filter((option) => props.survey.tests.find((test) => test.id === option.value)?.status === 'POSITIVE')]);
  };

  const handleCopy = () => {
    setIsLoading(true);
    copyTestsToNewSurvey(selected.map((s) => s.value), selectedDate).then(() => {
      props.onSave();
      setIsLoading(false);
      handleClose();
    });
  };

  const handleClose = () => setIsDialogOpen(false);

  useEffect(() => {
    if (props.survey) {
      const testOptions = props.survey.tests.map((test) => ({
        label: `${test.name} - ${RECORDED_STATUSES.includes(test.status) ? test.status.charAt(0) + test.status.toLocaleLowerCase().substr(1) : 'Not recorded'}`,
        value: test.id,
      }));
      setOptions(testOptions);
      setSelected(testOptions.filter((option) => option.label.includes('Inconclusive')));
    }
  }, [props.survey]);

  return (
    <div className="survey-modal__copy-to-new-survey-button">
      <IconButton
        onClick={() => {
          props.onOpen();
          setIsDialogOpen(true);
        }}
        onMouseEnter={(event) => {
          setIsTooltipOpen(true);
          setTooltipAnchor(event.currentTarget);
        }}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        <RepeatIcon />
      </IconButton>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="generate-labels-dialog"
      >
        <form>
          <DialogTitle id="form-dialog-title" className="batch-record-title">
            Copy Tests To New Survey
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="generate-labels-dialog__content">
            <DialogContentText>
              <div>Select the tests to copy</div>
              <div>By default, only inconclusive tests are selected</div>
            </DialogContentText>
            <MultiSelect
              options={options}
              value={selected}
              labelledBy="Select"
              onChange={setSelected}
              isOpen
            />
          </DialogContent>
          <DialogActions>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="create-survey-dialog__date-picker"
                margin="normal"
                id="date-picker-dialog"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={(date: MaterialUiPickersDate) => date && setSelectedDate(moment(Date.parse(date.toDateString())))}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            <Button
              style={{
                color: '#be7b7b',
              }}
              onClick={handleSelectPositive}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Select Positive'}
            </Button>
            <Button color="primary" onClick={handleCopy} disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Copy'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Popover
        className="tooltip"
        open={isTooltipOpen}
        anchorEl={tooltipAnchor}
        style={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Typography className="tooltip__text">Copy To New Survey</Typography>
      </Popover>
    </div>
  );
};
