import React, { useEffect, useState } from 'react';
import {
  AppBar, Button, Tab, Toolbar, Typography,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { SurveyModal } from '../survey/SurveyModal';
import './Home.scss';
import { SurveyTable } from '../survey/SurveyTable';
import { logout, retrieveSubjectNodes, retrieveSurveyListings } from '../../clients/client';
import { PopulationTable } from '../population/PopulationTable';
import { SubjectNode } from '../../models/SubjectNode';
import { SurveyListing } from '../../models/SurveyListing';
import { Scanner } from '../scanner/Scanner';
import { CreateSurveyButton } from '../survey/CreateSurveyButton';

export const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'edit' | 'view'>('view');
  const [tabValue, setTabValue] = useState('1');
  const [selectedSurveyId, setSelectedSurveyId] = useState<string>();
  const [surveyListings, setSurveyListings] = useState<SurveyListing[]>();
  const [population, setPopulation] = useState<SubjectNode[]>();

  const loadSurveyListings = () => {
    retrieveSurveyListings().then((response) => {
      setSurveyListings(response);
    });
  };

  const loadPopulation = () => {
    retrieveSubjectNodes().then((response: SubjectNode[]) => {
      setPopulation(response);
    });
  };

  useEffect(() => {
    loadSurveyListings();
    loadPopulation();
  }, []);

  const handleOpenSurvey = (surveyId: string) => {
    setSelectedSurveyId(surveyId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalMode('view');
    setIsModalOpen(false);
    loadSurveyListings();
  };

  return (
    <div className="desktop-home">
      <AppBar position="static" className="desktop-home__app-bar">
        <Toolbar className="desktop-home__toolbar">
          <Typography variant="h6">
            SRTI COVID-19 Tracker
          </Typography>
          <div className="desktop-home__action-buttons">
            <CreateSurveyButton reloadSurveys={loadSurveyListings} />
            <Button
              color="inherit"
              onClick={() => {
                logout();
              }}
            >
              Log out
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <TabContext value={tabValue}>
        <AppBar className="desktop-home__app-bar" position="static" color="transparent">
          <TabList
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
            aria-label="simple tabs example"
          >
            <Tab label="Surveys" value="1" />
            <Tab label="Population" value="2" />
            <Tab label="Scan Labels" value="3" />
          </TabList>
        </AppBar>
        <TabPanel value="1">
          <SurveyTable
            surveyListings={surveyListings}
            handleOpenSurvey={handleOpenSurvey}
          />
        </TabPanel>
        <TabPanel value="2">
          <PopulationTable
            population={population}
            reloadPopulation={loadPopulation}
          />
        </TabPanel>
        <TabPanel value="3">
          <Scanner />
        </TabPanel>
      </TabContext>

      {isModalOpen && (
        <SurveyModal
          surveyId={selectedSurveyId}
          mode={modalMode}
          handleCloseModal={handleCloseModal}
          reloadSurveyListings={loadSurveyListings}
          population={population}
        />
      )}
    </div>
  );
};
