import React, { FC, useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
  FormControlLabel, IconButton, Popover, Switch, Typography,
} from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import MultiSelect from 'react-multi-select-component';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import CloseIcon from '@material-ui/icons/Close';
import { Survey } from '../../models/Survey';
import { recordNewTestResults, recordTestResults } from '../../clients/client';
import { TestStatus } from '../../models/Statuses';

interface Props {
    survey: Survey;
    onSave: () => void;
}

export const BatchRecordTestResultsButton: FC<Props> = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipAnchor, setTooltipAnchor] = useState<HTMLSpanElement>();
  const [options, setOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [includeRecordedResults, setIncludeRecordedResults] = useState<boolean>(false);

  const handleMarkPositive = () => {
    handleRecord('POSITIVE');
  };

  const handleMarkNegative = () => {
    handleRecord('NEGATIVE');
  };

  const handleRecord = (testStatus: TestStatus) => {
    setIsLoading(true);
    if (includeRecordedResults) {
      recordTestResults(selected.map((s) => s.value), testStatus).then(() => {
        props.onSave();
        setIsLoading(false);
        handleClose();
      });
    } else {
      recordNewTestResults(selected.map((s) => s.value), testStatus).then(() => {
        props.onSave();
        setIsLoading(false);
        handleClose();
      });
    }
  };

  const handleClose = () => setIsDialogOpen(false);

  useEffect(() => {
    if (props.survey) {
      const testOptions = props.survey.tests.map((test) => ({ label: test.name, value: test.id }));
      setOptions(testOptions);
      setSelected(testOptions);
    }
  }, []);

  return (
    <div className="survey-modal__batch-record-button">
      <IconButton
        onClick={() => setIsDialogOpen(true)}
        onMouseEnter={(event) => {
          setIsTooltipOpen(true);
          setTooltipAnchor(event.currentTarget);
        }}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        <DoneAllIcon />
      </IconButton>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="generate-labels-dialog"
      >
        <form>
          <DialogTitle id="form-dialog-title" className="batch-record-title">
            Batch Record Test Results
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="generate-labels-dialog__content">
            <DialogContentText>
              <div>Select the test results to record</div>
              <div>Enable &apos;Include Recorded&apos; to overwrite existing test results</div>
            </DialogContentText>
            <MultiSelect
              options={options}
              value={selected}
              labelledBy="Select"
              onChange={setSelected}
              isOpen
            />
          </DialogContent>
          <DialogActions>
            <FormControlLabel
              className="switch"
              control={(
                <Switch
                  checked={includeRecordedResults}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setIncludeRecordedResults(event.target.checked); }}
                  name="checkedA"
                  color="primary"
                />
                  )}
              label="Include recorded"
            />
            <Button color="secondary" onClick={handleMarkPositive} disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Mark Positive'}
            </Button>
            <Button color="primary" onClick={handleMarkNegative} disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Mark Negative'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Popover
        className="tooltip"
        open={isTooltipOpen}
        anchorEl={tooltipAnchor}
        style={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Typography className="tooltip__text">Batch Record Test Results</Typography>
      </Popover>
    </div>
  );
};
