import React, { FC, useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
  CircularProgress, IconButton, Popover, Typography,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { changeSurveyName, deleteSurvey } from '../../clients/client';
import { Survey } from '../../models/Survey';

interface Props {
    survey: Survey;
    onSave: () => void;
    onClose: () => void;
}

export const SurveyEditButton: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [surveyName, setSurveyName] = useState<string | null>();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipAnchor, setTooltipAnchor] = useState<HTMLSpanElement>();

  useEffect(() => {
    setSurveyName(props.survey.name);
  }, [isOpen]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    changeSurveyName(props.survey.id, surveyName || `Survey ${props.survey.id}`).then(() => {
      props.onSave();
      setIsOpen(false);
    });
  };

  const handleClose = () => setIsOpen(false);

  const handleDelete = () => {
    setIsDeleting(true);
    deleteSurvey(props.survey.id).then(() => {
      props.onSave();
      setIsOpen(false);
      props.onClose();
    });
  };

  return (
    <div className="survey-modal__edit-button">
      <IconButton
        onClick={() => setIsOpen(true)}
        onMouseEnter={(event) => {
          setIsTooltipOpen(true);
          setTooltipAnchor(event.currentTarget);
        }}
        onMouseLeave={() => setIsTooltipOpen(false)}
      >
        <SettingsIcon />
      </IconButton>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            Edit survey:
            {' '}
            {props.survey.name}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Change survey name or delete it from database.
            </DialogContentText>
            <TextField value={surveyName} label="Survey Name" onChange={(event) => setSurveyName(event.target.value)} />
            <Dialog open={isConfirmOpen}>
              <DialogTitle>Are you sure you want to delete the survey?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Action cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsConfirmOpen(false)} color="primary" disabled={isDeleting}>
                  Cancel
                </Button>
                <Button color="primary" onClick={handleDelete} disabled={isDeleting}>
                  {isDeleting ? <CircularProgress /> : 'Delete'}
                </Button>
              </DialogActions>
            </Dialog>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => setIsConfirmOpen(true)} color="secondary">
              Delete Survey
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Popover
        className="tooltip"
        open={isTooltipOpen}
        anchorEl={tooltipAnchor}
        style={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Typography className="tooltip__text">Edit Survey</Typography>
      </Popover>
    </div>
  );
};
