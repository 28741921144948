import {
  CircularProgress, Divider, MenuItem, Select, Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import NotListedLocationOutlinedIcon from '@material-ui/icons/NotListedLocationOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Error';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import React, { FC, useEffect, useState } from 'react';
import {
  deleteSample, processSample, retrieveSample, toggleAbsentSample, toggleBadSample, toggleMissingSample,
} from '../../clients/client';
import { Sample } from '../../models/Sample';

interface Props {
    sample: Sample;
    onUpdateSample: (sample: Sample) => void;
}

export const SampleRow: FC<Props> = (props) => {
  const [isSettingStatus, setIsSettingStatus] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [sample, setSample] = useState<Sample>(props.sample);

  const handleUpdateSample = () => {
    retrieveSample(sample.id).then((newSample) => {
      setSample(newSample);
      setIsSettingStatus(false);
      setIsDeleting(false);
      props.onUpdateSample(newSample);
    });
  };

  useEffect(() => {
    setSample(props.sample);
  }, [props.sample]);

  return (
    <div key={sample.id}>
      <Typography
        variant="body2"
        component="div"
        className={`sample-row ${
          sample.deleted ? 'sample-row--deleted'
            : sample.status === 'BAD_SAMPLE' ? 'sample-row--bad-sample'
              : sample.status === 'MISSING' ? 'sample-row--missing'
                : sample.status === 'ABSENT' ? 'sample-row--absent'
                  : sample.status === 'PROCESSED' ? 'sample-row--processed' : ''}`}
      >
        <p className="sample-row__text">
          {sample.person.name}
        </p>
        <div className="sample-row__buttons">
          <Select
            className="sample-row__select"
            value={sample.status}
            variant="standard"
            disabled={sample.deleted || isSettingStatus}
            renderValue={(value: any) => {
              if (isSettingStatus) {
                return (
                  <CircularProgress
                    className="sample-row__loading-spinner"
                  />
                );
              }
              if (sample.deleted) return '---';
              switch (value) {
                case 'PROCESSED':
                  return <CircleCheckedFilled />;
                case 'BAD_SAMPLE':
                  return <ErrorIcon />;
                case 'MISSING':
                  return <NotListedLocationIcon />;
                case 'ABSENT':
                  return <HomeIcon />;
                default:
                  return <CircleChecked />;
              }
            }}
          >
            <MenuItem value="PROCESSED">
              <IconButton
                className="sample-row__button"
                onClick={() => {
                  setIsSettingStatus(true);
                  processSample(sample.id).then(() => {
                    handleUpdateSample();
                  });
                }}
              >
                {isSettingStatus ? (
                  <CircularProgress
                    className="sample-row__loading-spinner"
                  />
                ) : sample.status === 'PROCESSED'
                  ? <CircleCheckedFilled /> : <CircleChecked />}
                <span>Processed</span>
              </IconButton>
            </MenuItem>
            <MenuItem value="BAD_SAMPLE">
              <IconButton
                className="sample-row__button"
                onClick={() => {
                  setIsSettingStatus(true);
                  toggleBadSample(sample.id).then(() => {
                    handleUpdateSample();
                  });
                }}
              >
                {sample.status === 'BAD_SAMPLE'
                  ? <ErrorIcon /> : <ErrorOutlineIcon />}
                <span>Bad Sample</span>
              </IconButton>
            </MenuItem>
            <MenuItem value="MISSING">
              <IconButton
                className="sample-row__button"
                onClick={() => {
                  setIsSettingStatus(true);
                  toggleMissingSample(sample.id).then(() => {
                    handleUpdateSample();
                  });
                }}
              >
                {sample.status === 'MISSING'
                  ? <NotListedLocationIcon /> : <NotListedLocationOutlinedIcon />}
                <span>Missing Sample</span>
              </IconButton>
            </MenuItem>
            <MenuItem value="ABSENT">
              <IconButton
                className="sample-row__button"
                onClick={() => {
                  setIsSettingStatus(true);
                  toggleAbsentSample(sample.id).then(() => {
                    handleUpdateSample();
                  });
                }}
              >
                {sample.status === 'ABSENT'
                  ? <HomeIcon /> : <HomeOutlinedIcon />}
                <span>Absent Person</span>
              </IconButton>
            </MenuItem>
          </Select>
          <IconButton
            className="sample-row__button"
            onClick={() => {
              setIsDeleting(true);
              deleteSample(sample.id).then(() => {
                handleUpdateSample();
              });
            }}
          >
            {isDeleting
              ? <CircularProgress className="sample-row__loading-spinner" /> : sample.deleted
                ? <DeleteIcon /> : <DeleteOutlineIcon />}
          </IconButton>
        </div>
      </Typography>
      <Divider light className="card__divider--sample" />
    </div>
  );
};
