import React, { FC, useEffect, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './SurveyModal.scss';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import ErrorIcon from '@material-ui/icons/Error';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import { Survey } from '../../models/Survey';
import { retrievePeople, retrieveSurvey } from '../../clients/client';
import { SubjectNode } from '../../models/SubjectNode';
import { SurveyEditButton } from './SurveyEditButton';
import { GenerateLabelsButton } from './GenerateLabelsButton';
import { BatchRecordTestResultsButton } from './BatchRecordTestResultsButton';
import { Person } from '../../models/Person';
import { SurveyNotes } from './SurveyNotes';
import { TestCard } from '../test_card/TestCard';
import { AddTestButton } from './AddTestButton';
import { CopyToNewSurveyButton } from './CopyToNewSurveyButton';

interface Props {
  handleCloseModal: () => void;
  reloadSurveyListings: () => void;
  surveyId?: string;
  mode?: 'edit' | 'view';
  population?: SubjectNode[];
}

export const SurveyModal: FC<Props> = (props: Props) => {
  const [survey, setSurvey] = useState<Survey>();
  const [isLoading, setIsLoading] = useState(false);
  const [openTests, setOpenTests] = useState<string[]>([]);
  const [subjects, setSubjects] = useState<Person[]>([]);

  const handleEditSurvey = () => {
    props.reloadSurveyListings();
    if (props.surveyId) {
      retrieveSurvey(props.surveyId).then((response) => {
        setSurvey(response);
      });
    }
  };

  useEffect(() => {
    if (props.surveyId) {
      setIsLoading(true);
      retrieveSurvey(props.surveyId).then((response) => {
        setSurvey(response);
        setIsLoading(false);
      });
      retrievePeople().then((result) => {
        setSubjects(result);
      });
    }
  }, [props.surveyId]);

  const tests = survey?.tests.sort((a, b) => (parseInt(a.id) < parseInt(b.id) ? -11 : 1));
  const numCreatedTests = (survey?.tests.filter((t) => t.status === 'CREATED').length) || 0;
  const numProcessedTests = survey ? survey.tests.length - numCreatedTests : 0;
  const numTestResultsRecorded = survey ? survey.tests.filter((t) => t.status === 'POSITIVE' || t.status === 'NEGATIVE').length : 0;

  return (
    <div className="survey-modal">
      <div className="survey-modal__grayed-background" />
      {!isLoading ? (
        <div className="survey-modal__box">
          <div className={`survey-modal__row survey-modal__title-row survey-modal__title-row--${props.mode === 'view' ? 'view' : 'edit'}`}>
            <Typography variant="h6">
              {survey ? props.mode === 'view' ? (
                <span
                  role="button"
                  className="title__name"
                >
                  {`${survey.name} (${moment(survey.startDate).format('MM/DD/YY')})`}
                </span>
              ) : 'Edit Population Survey' : 'Create Population Survey'}
              {props.mode === 'view' && (
              <span>
                Processed
                <b>
                  {' '}
                  {numProcessedTests}
                  {' '}
                  /
                  {' '}
                  {survey?.tests.length}
                </b>
                {' '}
                tests. Recorded
                <b>
                  {' '}
                  {numTestResultsRecorded}
                  {' '}
                  /
                  {' '}
                  {survey?.tests.length}
                </b>
                {' '}
                results.
              </span>
              )}
            </Typography>
            <div className="survey-modal__action-buttons">
              {survey && (
                <>
                  <GenerateLabelsButton survey={survey} />
                  {survey.driveBy && (
                    <Button
                      className="survey-modal__labels-button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        window.open(`/drive-by?survey=${survey?.id}`);
                      }}
                    >
                      Print Check-list
                    </Button>
                  )}
                  <BatchRecordTestResultsButton survey={survey} onSave={handleEditSurvey} />
                  <CopyToNewSurveyButton onOpen={handleEditSurvey} survey={survey} onSave={handleEditSurvey} />
                  <SurveyEditButton
                    survey={survey}
                    onSave={handleEditSurvey}
                    onClose={props.handleCloseModal}
                  />
                </>
              )}
              <Button className="survey-modal__close-button" onClick={props.handleCloseModal}>
                <CloseIcon />
              </Button>
            </div>
          </div>

          <div className="survey-modal__content">
            <div className="survey-modal__view-mode">

              <div className="color-legend">
                <div className="color-legend__item color-legend__item">
                  <div className="color-legend__icon color-legend__icon--gray">
                    <CircleChecked />
                  </div>
                  Unprocessed
                </div>
                <div className="color-legend__item color-legend__item">
                  <div className="color-legend__icon color-legend__icon--dark-gray">
                    <NotListedLocationIcon />
                  </div>
                  Missing Sample
                </div>
                <div className="color-legend__item color-legend__item">
                  <div className="color-legend__icon color-legend__icon--yellow">
                    <ErrorIcon />
                  </div>
                  Bad Sample
                </div>
                <div className="color-legend__item color-legend__item">
                  <div className="color-legend__icon color-legend__icon--blue">
                    <CircleCheckedFilled />
                  </div>
                  Processed
                </div>
                <div className="color-legend__item color-legend__item">
                  <div className="color-legend__icon color-legend__icon--green">
                    <RemoveCircleOutlinedIcon />
                  </div>
                  Negative test
                </div>
                <div className="color-legend__item color-legend__item">
                  <div className="color-legend__icon color-legend__icon--red">
                    <AddCircleOutlinedIcon />
                  </div>
                  Positive test
                </div>
              </div>
              <div className="survey-modal__test-cards">
                <SurveyNotes survey={survey} />
                {tests?.map((test, index) => (
                  <TestCard
                    test={test}
                    reload={handleEditSurvey}
                    population={props.population}
                    openTests={openTests}
                    setOpenTests={setOpenTests}
                    subjects={subjects}
                  />
                ))}
                <AddTestButton reloadSurveys={handleEditSurvey} survey={survey} population={props.population} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      )}

    </div>
  );
};
